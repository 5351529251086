<template>
    <div class="friend-referral">
        <StackRouterHeaderBar :left-button="'close'" />
        <span class="title" v-html="'초대할 친구를 알려주세요!'" />
        <span class="desc" v-html="'가입한 친구가 회원님의 친구가 맞는지<br/>확인하는 목적으로 이용됩니다.'" />

        <div class="input-container w-100">
            <p class="f-12 f-medium m-b-8" v-html="'친구 이름'" />
            <TextareaWithX :isInputMode="true" v-model="name" :placeholder="$translate('PLACEHOLDER_NAME')" />
        </div>
        <div class="input-container w-100">
            <p class="f-12 f-medium m-b-8" v-html="'친구 휴대폰 번호'" />
            <TextareaWithX :isInputMode="true" v-model="number" :placeholder="$translate('PLACEHOLDER_PHONE_NUMBER')" />
        </div>
        <BottomButton :label="'제출'" @click="clickCta" :disabled="!activate" />
    </div>
</template>
<script>
import chatService from '@/services/chat'
import BottomButton from '@/components/app/BottomButton'
import TextareaWithX from '@/components/app/TextareaWithX'

export default {
    name: 'FriendReferralPage',
    data: () => ({
        name: '',
        number: '',
    }),
    components: {
        BottomButton,
        TextareaWithX,
    },
    props: {
        message_id: {
            type: Number,
            default: null,
        },
    },
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        activate() {
            if (isNaN(this.number)) return false

            return this.name.length > 0 && this.number.length === 11
        },
    },
    mounted() {
        this.init()
    },
    beforeDestroy() {},
    methods: {
        async init() {},
        async clickCta() {
            if (!this.activate) return
            try {
                const data = new FormData()
                data.append('friend_name', this.name)
                data.append('friend_phone_number', this.number)
                data.append('original_message_id', this.message_id)
                await chatService.referral(this.chat.id, data)
                this.$toast.success('초대 완료')
                this.$bus.$emit('notiActionDone', this.message_id)
                this.$stackRouter.pop()
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.friend-referral {
    height: 100vh;
    @include flex;

    .title {
        color: black;
        padding: 0 16px;
        @include f-medium;
        font-size: 24px;
    }

    .desc {
        padding: 0 16px;
        @include f-medium;
        color: $blue-primary;
        font-size: 14px;
        margin-top: 16px;
        margin-bottom: 8px;
    }
    .input-container {
        padding: 12px 16px;
    }
}
</style>
